import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LayoutSignpost from "../../layout/LayoutSignpost"
import Heading from "../Heading/Heading"
import Filter from "../Filter/FilterWork"
import Gallery from "../Gallery/Gallery"
import { frontmatterPathBuilder } from "../../helpers/pathBuilders"

export default () => {
    const data = useStaticQuery(
        graphql`
            query {
                workheader: markdownRemark(
                    fields: { sourceInstanceName: { eq: "work" } }
                ) {
                    excerpt(format: HTML)
                    frontmatter {
                        title
                        description
                        pathDirectory
                        pathName
                        signpost_link_text
                    }
                }
            }
        `
    )
    const page = data.workheader

    return (
        <LayoutSignpost
            heading={
                <Heading
                    headingLevel="2"
                    headingTitle={frontmatterPathBuilder(page).title}
                    headingDescription={
                        frontmatterPathBuilder(page).description
                    }
                    signpostLinkPath={
                        frontmatterPathBuilder(page).pathDirectory +
                        frontmatterPathBuilder(page).pathName
                    }
                    signpostLinkText={
                        frontmatterPathBuilder(page).signpost_link_text
                    }
                />
            }
            filter={<Filter />}
            gallery={<Gallery queryType="limited" />}
            richText={true}
        />
    )
}
