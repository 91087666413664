import React from "react"
import mugshotVideoMp4 from "../../videos/mugshot.mp4"
import mugshotPoster from "../../images/mugshot.jpg"
import styles from "./Mugshot.module.scss"

export default () => {
    return (
        <div className={styles.mugshot}>
            <div className={styles.mugshot__mask}>
                <video
                    autoPlay
                    loop
                    muted
                    width="300"
                    height="300"
                    playsInline
                    poster={mugshotPoster}
                >
                    <source src={mugshotVideoMp4} type="video/mp4" />
                </video>
                <p>
                    Your browser may not support the HTML5 video tag being used
                    to embed this video asset.
                </p>
            </div>
        </div>
    )
}
