import React from "react"
import { motion } from "framer-motion"
import { getRandomFloat } from "../../helpers/getRandom"
import styles from "./BackgroundAnimation.module.scss"

export default () => {
    const Circle = props => (
        <motion.circle
            initial={{ cx: props.r * -1 + "%" }}
            animate={{ cx: 100 + props.r / 1 + "%" }}
            transition={{
                ease: "linear",
                loop: Infinity,
                delay: props.delay,
                duration: props.duration,
            }}
            cx={props.r * -1 + "%"}
            cy={props.cy + "%"}
            r={props.r + props.unit}
            className={props.className}
        />
    )

    const Rect = props => (
        <motion.rect
            initial={{ x: props.width * -1 + "%" }}
            animate={{
                x: 100 + props.width * 1 + "%",
            }}
            transition={{
                ease: "linear",
                loop: Infinity,
                delay: props.delay,
                repeatDelay: props.delay,
                duration: props.duration,
            }}
            width={props.width + "%"}
            height={props.height + "%"}
            x={props.width * -1 + "%"}
            y={props.y + "%"}
            className={props.className}
        />
    )

    const circleMaker = (amount, rmin, rmax, duration, style, unit = "%") => {
        const circleLayerArray = []
        let index = 0

        for (index; index < amount; index++) {
            const cx = getRandomFloat(0, 100)
            const cy = getRandomFloat(0, 100)
            const r = getRandomFloat(rmin, rmax)
            const dur = getRandomFloat(duration / 1.4, duration * 1.4)
            const del = getRandomFloat(0, dur)
            const un = unit
            const key = getRandomFloat(0, 99999)
            const className = styles[`background_animation__circle____${style}`]

            circleLayerArray.push(
                <Circle
                    cx={cx}
                    cy={cy}
                    r={r}
                    duration={dur}
                    delay={del}
                    unit={un}
                    key={key}
                    className={className}
                />
            )
        }

        return (
            <motion.g key={"circleArray" + index}>{circleLayerArray}</motion.g>
        )
    }

    const rectMaker = (
        amount,
        widthMin,
        widthMax,
        heightMin,
        heightMax,
        duration,
        style
    ) => {
        const rectLayerArray = []
        let index = 0

        for (index; index < amount; index++) {
            const x = getRandomFloat(0, 100)
            const y = getRandomFloat(8, 92)
            const width = getRandomFloat(widthMin, widthMax)
            const height = getRandomFloat(heightMin, heightMax)
            const dur = getRandomFloat(duration / 1.4, duration * 1.4)
            const del = getRandomFloat(0, dur)
            const key = getRandomFloat(0, 99999)
            const className =
                styles[
                    `background_animation__${style ? "rect____light" : "rect"}`
                ]
            rectLayerArray.push(
                <Rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    duration={dur}
                    delay={del}
                    key={key}
                    className={className}
                />
            )
        }

        return (
            <motion.g key={"rectArray" + getRandomFloat(0, 99999)}>
                {rectLayerArray}
            </motion.g>
        )
    }

    // circle props: amount, rmin, rmax, duration, style, unit, offsetAmount
    // rect props: amount, widthMin, widthMax, heightMin, heightMax, duration, style, offsetAmount
    const shapes = [
        rectMaker(6, 24, 50, 24, 50, 43, false),
        rectMaker(8, 12, 21, 24, 42, 41, false),
        rectMaker(12, 3, 10, 5, 12, 35, false),
        circleMaker(3, 16, 24, 38, "5", "%"),
        circleMaker(5, 13, 18, 28, "4", "%"),
        circleMaker(8, 7, 12, 20, "3", "%"),
        circleMaker(15, 0.1, 7, 12, "2", "%"),
        circleMaker(24, 0, 2, 8, "1", "%"),
        rectMaker(6, 4, 18, 6, 16, 11, true),
        rectMaker(10, 0.1, 2, 1.5, 2, 8, true),
        circleMaker(19, 0.8, 3, 12, "color", "px"),
        circleMaker(35, 0.3, 1, 6, "color", "px"),
    ]

    return shapes
}
