import React from "react"
import styles from "./Hero.module.scss"
import NetworkList from "../NetworkList/NetworkList"
import BackgroundAnimation from "../Background/BackgroundAnimation"

export default props => {
    return (
        <>
            <BackgroundAnimation />
            <section className={styles.hero}>
                <div className={styles.hero__center}>
                    <span className={styles.hero__title_primary}>
                        {props.heroTitlePrimary}
                    </span>
                    <span className={styles.hero__title_secondary}>
                        {props.heroTitleSecondary}
                    </span>
                </div>
                <div className={styles.hero__bottom}>
                    <div className={styles.hero__bottom_inner}>
                        <NetworkList />
                    </div>
                </div>
            </section>
        </>
    )
}
