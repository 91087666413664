import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import styles from "./Gallery.module.scss"
import {
    pagePathBuilder,
    frontmatterPathBuilder,
} from "../../helpers/pathBuilders"
import { variantsTertiary } from "../../helpers/motionVariants"

export default props => {
    const data = useStaticQuery(
        graphql`
            query {
                full: allMarkdownRemark(
                    sort: { fields: [frontmatter___date], order: DESC }
                    filter: {
                        fields: { sourceInstanceName: { eq: "work-single" } }
                    }
                ) {
                    totalCount
                    edges {
                        node {
                            id
                            frontmatter {
                                pathDirectory
                                pathName
                                title
                                description
                                thumbnail {
                                    childImageSharp {
                                        fluid(maxWidth: 600, quality: 95) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                limited: allMarkdownRemark(
                    limit: 6
                    sort: { fields: [frontmatter___date], order: DESC }
                    filter: {
                        fields: { sourceInstanceName: { eq: "work-single" } }
                    }
                ) {
                    totalCount
                    edges {
                        node {
                            id
                            frontmatter {
                                pathDirectory
                                pathName
                                title
                                description
                                thumbnail {
                                    childImageSharp {
                                        fluid(maxWidth: 600) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const type = props.queryType ? props.queryType : "full"

    return (
        <motion.div
            variants={variantsTertiary}
            initial="hidden"
            animate="enter"
        >
            <ul className={styles.gallery}>
                {data[type].edges.map(({ node }) => (
                    <li key={node.id} className={styles.gallery__item}>
                        <Link
                            to={pagePathBuilder(node)}
                            className={styles.gallery__link}
                        >
                            <div className={styles.gallery__text_wrapper}>
                                <span className={styles.gallery__item_title}>
                                    {frontmatterPathBuilder(node).title}
                                </span>
                                <span
                                    className={styles.gallery__item_description}
                                >
                                    {frontmatterPathBuilder(node).description}
                                </span>
                            </div>
                            {frontmatterPathBuilder(node).thumbnail && (
                                <Img
                                    fluid={
                                        frontmatterPathBuilder(node).thumbnail
                                            .childImageSharp.fluid
                                    }
                                    className={styles.gallery__item_image}
                                />
                            )}
                        </Link>
                    </li>
                ))}
            </ul>
        </motion.div>
    )
}
