import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Metadata from "../components/Metadata/Metadata"
import "../styles/screen.scss"
import Hero from "../components/Hero/Hero"
import SignpostAbout from "../components/Signpost/SignpostAbout"
import SignpostWork from "../components/Signpost/SignpostWork"
import SignpostWeblog from "../components/Signpost/SignpostWeblog"

export default () => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        network {
                            email {
                                url
                            }
                        }
                    }
                }
            }
        `
    )
    const emailUrl = data.site.siteMetadata.network.email.url
    const TitlePrimary = () => (
        <>
            Front-end designer
            <br /> and developer
        </>
    )
    const TitleSecondary = () => (
        <>
            Available for hire - <a href={emailUrl}>Say hey</a>
        </>
    )

    return (
        <>
            <Metadata title="Home" />
            <Hero
                heroTitlePrimary={<TitlePrimary />}
                heroTitleSecondary={<TitleSecondary />}
            />
            <SignpostAbout />
            <SignpostWork />
            <SignpostWeblog />
        </>
    )
}
