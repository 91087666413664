import React from "react"
import "./Layout.scss"

export default props => (
    <article className="layout layout____signpost">
        {props.heading ? props.heading : null}

        <section className="layout__grid">
            {props.filter ? (
                <section className="layout__grid____top">
                    {props.filter ? props.filter : null}
                </section>
            ) : null}

            {props.main || props.aside ? (
                <section
                    className={props.aside ? "layout__grid____content" : ""}
                >
                    {props.main ? (
                        <main
                            className={`layout__grid____primary ${
                                props.richText === true ? "rich_text" : null
                            }`}
                        >
                            {props.main}
                        </main>
                    ) : null}

                    {props.aside ? (
                        <aside className="layout__grid____secondary">
                            {props.aside}
                        </aside>
                    ) : null}
                </section>
            ) : null}
        </section>

        {props.gallery ? props.gallery : null}
    </article>
)
