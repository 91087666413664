import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LayoutSignpost from "../../layout/LayoutSignpost"
import Heading from "../Heading/Heading"
import Aside from "../Aside/AsideAbout"
import { Link } from "gatsby"
import { frontmatterPathBuilder } from "../../helpers/pathBuilders"
import ArrowFull from "../../images/arrow_full.svg"
import arrowStyle from "../Arrow/Arrow.module.scss"

export default () => {
    const data = useStaticQuery(
        graphql`
            query {
                markdownRemark(
                    fields: { sourceInstanceName: { eq: "about" } }
                ) {
                    excerpt(format: HTML)
                    frontmatter {
                        title
                        description
                        pathDirectory
                        pathName
                        signpost_link_text
                    }
                }
            }
        `
    )
    const page = data.markdownRemark
    const Main = () => (
        <>
            <div dangerouslySetInnerHTML={{ __html: page.excerpt }} />
            <p>
                <Link
                    to={
                        frontmatterPathBuilder(page).pathDirectory +
                        frontmatterPathBuilder(page).pathName
                    }
                >
                    {frontmatterPathBuilder(page).signpost_link_text}
                    <ArrowFull className={arrowStyle.arrow} />
                </Link>
            </p>
        </>
    )

    return (
        <LayoutSignpost
            heading={
                <Heading
                    headingLevel="2"
                    headingTitle={frontmatterPathBuilder(page).title}
                    headingDescription={
                        frontmatterPathBuilder(page).description
                    }
                    signpostLinkPath={
                        frontmatterPathBuilder(page).pathDirectory +
                        frontmatterPathBuilder(page).pathName
                    }
                    signpostLinkText={
                        frontmatterPathBuilder(page).signpost_link_text
                    }
                />
            }
            main={<Main />}
            aside={<Aside />}
            richText={true}
        />
    )
}
