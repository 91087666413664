import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"
import Mugshot from "../Mugshot/Mugshot"
import NetworkList from "../NetworkList/NetworkList"
import styles from "./Aside.module.scss"
import { variantsSecondary } from "../../helpers/motionVariants"

export default () => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        techlist {
                            html {
                                name
                                list
                            }
                            css {
                                name
                                list
                            }
                            libraries {
                                name
                                list
                            }
                            cms {
                                name
                                list
                            }
                            buildtools {
                                name
                                list
                            }
                            static {
                                name
                                list
                            }
                            also {
                                name
                                list
                            }
                        }
                    }
                }
            }
        `
    )

    const detailData = data.site.siteMetadata["techlist"]

    const ListItem = obj =>
        Object.keys(obj).map(key => (
            <li className={styles.aside__list_item} key={key}>
                <span>
                    <span className={styles.aside__list_item_header}>
                        {obj[key].name}:
                    </span>{" "}
                    {obj[key].list.join(", ")}
                </span>
            </li>
        ))

    return (
        <motion.div
            className={`${styles.aside} ${styles.aside____about}`}
            variants={variantsSecondary}
            initial="hidden"
            animate="enter"
        >
            <div>
                <Mugshot />
            </div>

            <div className={styles.aside__techlist}>
                <span className={styles.aside__heading}>Tech List:</span>
                <ul className={styles.aside__techlist_list}>
                    {ListItem(detailData)}
                </ul>
            </div>

            <div className={styles.aside__network}>
                <span className={styles.aside__heading}>Network:</span>
                <NetworkList narrow={true} />
            </div>
        </motion.div>
    )
}
