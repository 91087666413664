import React from "react"
import BackgroundGenerator from "./BackgroundGenerator"
import { motion } from "framer-motion"
import styles from "./BackgroundAnimation.module.scss"

const BackgroundAnimation = React.memo(() => {
    return (
        <div className={styles.background_animation}>
            <motion.svg
                className={styles.background_animation__svg}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <BackgroundGenerator />

                <defs>
                    <linearGradient
                        id="color_layer_01"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientUnits="objectBoundingBox"
                    >
                        <stop
                            offset="0.1"
                            className={
                                styles.background_animation__circle____1_top
                            }
                        />
                        <stop
                            offset="1"
                            className={
                                styles.background_animation__circle____1_bottom
                            }
                        />
                    </linearGradient>
                    <linearGradient
                        id="color_layer_02"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientUnits="objectBoundingBox"
                    >
                        <stop
                            offset="0.1"
                            className={
                                styles.background_animation__circle____2_top
                            }
                        />
                        <stop
                            offset="1"
                            className={
                                styles.background_animation__circle____2_bottom
                            }
                        />
                    </linearGradient>
                    <linearGradient
                        id="color_layer_03"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientUnits="objectBoundingBox"
                    >
                        <stop
                            offset="0.1"
                            className={
                                styles.background_animation__circle____3_top
                            }
                        />
                        <stop
                            offset="1"
                            className={
                                styles.background_animation__circle____3_bottom
                            }
                        />
                    </linearGradient>
                    <linearGradient
                        id="color_layer_04"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientUnits="objectBoundingBox"
                    >
                        <stop
                            offset="0.1"
                            className={
                                styles.background_animation__circle____4_top
                            }
                        />
                        <stop
                            offset="1"
                            className={
                                styles.background_animation__circle____4_bottom
                            }
                        />
                    </linearGradient>
                    <linearGradient
                        id="color_layer_05"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientUnits="objectBoundingBox"
                    >
                        <stop
                            offset="0.1"
                            className={
                                styles.background_animation__circle____5_top
                            }
                        />
                        <stop
                            offset="1"
                            className={
                                styles.background_animation__circle____5_bottom
                            }
                        />
                    </linearGradient>
                    <linearGradient
                        id="color_layer_06"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientUnits="objectBoundingBox"
                    >
                        <stop
                            offset="0.1"
                            className={
                                styles.background_animation__circle____6_top
                            }
                        />
                        <stop
                            offset="1"
                            className={
                                styles.background_animation__circle____6_bottom
                            }
                        />
                    </linearGradient>
                </defs>
            </motion.svg>
            <motion.div
                className={styles.background_animation__soft_light}
                animate={{ opacity: [0.85, 0.72, 0.83, 0.7, 0.87, 0.73, 0.85] }}
                transition={{ loop: Infinity, duration: 8 }}
            />
            <div className={styles.background_animation__bottom_fade} />
        </div>
    )
})

export default BackgroundAnimation
